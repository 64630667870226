import { CardContent, Grid, Typography, Box, Card, Chip, IconButton, Button, TextField, Switch, FormControlLabel } from "@material-ui/core";
import React, { useState } from "react";
import { GridGrow } from "../../../components/GridGrow";
import { PhoneNumberLink } from "../../../components/PhoneNumberLink";
import { useAlert } from "../../../hooks/useAlert";
import { CustomerService } from "../../../services/customer/CustomerService";
import PersonIcon from "@material-ui/icons/Person";
import LocationIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import AddIcon from "@material-ui/icons/Add";
import { TagBubble } from "../../../components/TagBubble";
import { Tag } from "../../../entities/customer/Tag";
import { SelectTagDialog } from "../dialog/SelectTagDialog";
import { TagService } from "../../../services/customer/TagService";
import { useCustomerDetailPage } from "../CustomerDetailPage";
import { EditableField, SubmitField } from "../../../components/SubmitTextField";
import { Customer } from "../../../entities/customer/Customer";
import { AddressLookupField } from "../../../components/address/AddressLookupField";
import { GeocodedAddress } from "../../../entities/customer/GeocodedAddress";
import { ButtonLink } from "../../../components/ButtonLink";
import { ManualAddressDialog } from "../../../components/address/ManualAddressDialog";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

export function CustomerContactCard() {
	const context = useCustomerDetailPage();
	const { customer } = context;

	return (
		<Card>
			<Box paddingLeft={2} paddingTop={1} paddingRight={2}>
				<Grid container spacing={2} alignItems="center">
					<Grid item>
						<Typography variant="h6">Contact</Typography>
					</Grid>
					<GridGrow />
				</Grid>
			</Box>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={6}>
								<EditableField<Customer>
									type="text"
									value={customer.name}
									size="small"
									selectOnFocus
									update={{
										request: (name) => CustomerService.update({ customerId: customer.id, name: name }),
										onSuccess: context.updateCustomer,
										successMessage: "Name updated",
										required: true,
									}}
									view={(onEdit) => (
										<Grid container wrap="nowrap" alignItems="center" spacing={1}>
											<Grid item>
												<IconButton onClick={onEdit} size="small" color="primary">
													<PersonIcon />
												</IconButton>
											</Grid>
											<Grid item>
												<Typography>{customer.name}</Typography>
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
							<Grid item>
								<EditableField<Customer>
									type="text"
									value={customer.customerCode}
									size="small"
									selectOnFocus
									helperText="Customer Code: Must be unique"
									update={{
										request: (customerCode) => CustomerService.updateCode({ customerId: customer.id, customerCode }),
										onSuccess: context.updateCustomer,
										successMessage: "Customer Code updated",
										required: true,
									}}
									view={(onEdit) => (
										<Grid container wrap="nowrap" alignItems="center" spacing={2}>
											<Grid item>
												<IconButton onClick={onEdit} size="small" color="primary">
													<Typography style={{ fontSize: "1.5rem", paddingLeft: 4, paddingRight: 4 }}>#</Typography>
												</IconButton>
											</Grid>
											<Grid item>
												<Typography>{customer.customerNumber}</Typography>
											</Grid>
											<Grid item>
												<Typography>{customer.customerCode}</Typography>
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<CustomerEmails />
					</Grid>
					<Grid item xs={12}>
						<CustomerPhones />
					</Grid>
					<Grid item xs={12}>
						<BillingAddress />
					</Grid>
					<Grid item xs={12}>
						<CustomerDeliveryInstructions />
					</Grid>
					<Grid item xs={12}>
						<CustomerTags />
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

function CustomerEmails() {
	const context = useCustomerDetailPage();
	const { customer } = context;

	const [editEmail, setEditEmail] = useState(false);
	const firstEmail = customer.email ?? customer.emailSecondary;
	const secondEmail = customer.email && customer.emailSecondary ? customer.emailSecondary : null;

	const onChangeAllowEmailSend = async () => {
		const result = await CustomerService.update({ customerId: customer.id, allowSendEmail: !customer.allowSendEmail });
		if (result.success) {
			context.updateCustomer(result.data);
		}
	};

	if (editEmail) {
		return (
			<Grid container spacing={1}>
				<Grid item>
					<SubmitField<Customer>
						type="text"
						autoFocus={false}
						selectOnFocus
						helperText="Primary email"
						value={customer.email}
						size="small"
						update={{
							request: (email) => CustomerService.update({ customerId: customer.id, email: email }),
							onSuccess: (c) => {
								context.updateCustomer(c);
								setEditEmail(false);
							},
							successMessage: "Primary email updated",
							required: false,
						}}
						onCancel={() => setEditEmail(false)}
					/>
				</Grid>
				<Grid item>
					<SubmitField<Customer>
						type="text"
						helperText="Secondary email"
						autoFocus={false}
						selectOnFocus
						value={customer.emailSecondary}
						size="small"
						update={{
							request: (email) => CustomerService.update({ customerId: customer.id, emailSecondary: email }),
							onSuccess: (c) => {
								context.updateCustomer(c);
								setEditEmail(false);
							},
							successMessage: "Secondary email updated",
							required: false,
						}}
						onCancel={() => setEditEmail(false)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button color="secondary" variant="outlined" onClick={() => setEditEmail(false)}>
						Cancel
					</Button>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container wrap="nowrap" alignItems="center" spacing={1}>
			<Grid item xs={6}>
				<Grid container wrap="nowrap" alignItems="center" spacing={1}>
					<Grid item>
						<IconButton onClick={() => setEditEmail(true)} size="small" color="primary">
							<EmailIcon />
						</IconButton>
					</Grid>
					<Grid item>
						<Typography color={firstEmail ? "textPrimary" : "textSecondary"}>
							{firstEmail ? firstEmail : "No Email"}
							{secondEmail && (
								<>
									<br />
									{secondEmail}
								</>
							)}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			{customer.email != null && (
				<Grid item>
					<FormControlLabel
						control={<Switch onChange={onChangeAllowEmailSend} color="primary" checked={customer.allowSendEmail} />}
						label="Enable Automated Email"
					/>
				</Grid>
			)}
		</Grid>
	);
}

function CustomerPhones() {
	const context = useCustomerDetailPage();
	const { customer } = context;

	const [editPhone, setEditPhone] = useState(false);

	if (editPhone) {
		return (
			<Grid container spacing={1}>
				<Grid item>
					<SubmitField<Customer>
						type="phone"
						autoFocus={false}
						selectOnFocus
						helperText="Primary phone"
						value={customer.phoneNumber}
						allowExtensions={true}
						size="small"
						update={{
							request: (phoneNumber) => CustomerService.update({ customerId: customer.id, phoneNumber: phoneNumber }),
							onSuccess: (c) => {
								context.updateCustomer(c);
								setEditPhone(false);
							},
							successMessage: "Primary phone number updated",
							required: false,
						}}
						onCancel={() => setEditPhone(false)}
					/>
				</Grid>
				<Grid item>
					<SubmitField<Customer>
						type="phone"
						helperText="Secondary phone"
						autoFocus={false}
						selectOnFocus
						value={customer.phoneNumberSecondary}
						allowExtensions={true}
						size="small"
						update={{
							request: (phoneNumber) => CustomerService.update({ customerId: customer.id, phoneNumberSecondary: phoneNumber }),
							onSuccess: (c) => {
								context.updateCustomer(c);
								setEditPhone(false);
							},
							successMessage: "Secondary phone number updated",
							required: false,
						}}
						onCancel={() => setEditPhone(false)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button color="secondary" variant="outlined" onClick={() => setEditPhone(false)}>
						Cancel
					</Button>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container wrap="nowrap" alignItems="center" spacing={1}>
			<Grid item>
				<IconButton onClick={() => setEditPhone(true)} size="small" color="primary">
					<PhoneIcon />
				</IconButton>
			</Grid>
			<Grid item>
				<Typography>
					{customer.phoneNumber ? <PhoneNumberLink phoneNumber={customer.phoneNumber} /> : "No Phone Number"}{" "}
					{customer.phoneNumberSecondary && (
						<>
							<span style={{ paddingLeft: 5, paddingRight: 5 }}>or</span> <PhoneNumberLink phoneNumber={customer.phoneNumberSecondary} />
						</>
					)}
				</Typography>
			</Grid>
		</Grid>
	);
}

function BillingAddress() {
	const context = useCustomerDetailPage();
	const { customer } = context;
	const alert = useAlert();

	const [mode, setMode] = useState<"view" | "edit" | "change">("view");
	const [disabled, setDisabled] = useState(false);

	const [manualGeocode, setManualGeocode] = useState(false);

	const [street, setStreet] = useState(customer.address.street);
	const [city, setCity] = useState(customer.address.city);
	const [state, setState] = useState(customer.address.state);
	const [postalCode, setPostalCode] = useState(customer.address.postalCode);
	const [county, setCounty] = useState(customer.address.county);

	const updateAddress = async (address: GeocodedAddress) => {
		setDisabled(true);
		const result = await CustomerService.updateAddress({
			customerId: customer.id,
			...address,
		});
		setDisabled(false);

		if (result.success) {
			context.updateCustomer(result.data);
			setMode("view");
			alert.success("Address updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const updateAddressManual = async () => {
		setDisabled(true);
		const result = await CustomerService.updateAddress({
			customerId: customer.id,
			...customer.address,
			street,
			city,
			state,
			postalCode,
			county,
		});
		setDisabled(false);

		if (result.success) {
			context.updateCustomer(result.data);
			setMode("view");
			alert.success("Address updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const cancelUpdate = () => {
		setStreet(customer.address.street);
		setCity(customer.address.city);
		setState(customer.address.state);
		setPostalCode(customer.address.postalCode);
		setCounty(customer.address.county);
		setMode("view");
	};

	if (mode === "change") {
		return (
			<>
				<AddressLookupField onSelect={(a) => updateAddress(a)} disabled={disabled} fullWidth placeholder="Enter Billing Address" />
				<Button color="secondary" variant="outlined" disabled={disabled} onClick={cancelUpdate} style={{ marginTop: 10 }}>
					Cancel
				</Button>
			</>
		);
	}

	if (mode === "edit") {
		return (
			<form
				onSubmit={(e) => {
					e.preventDefault();
					updateAddressManual();
				}}
			>
				<ManualAddressDialog open={manualGeocode} address={customer.address} onClose={() => setManualGeocode(false)} onSubmit={updateAddress} />
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography gutterBottom>Billing Address</Typography>
						<TextField fullWidth variant="outlined" label="Street" value={street} onChange={(e) => setStreet(e.target.value)} />
					</Grid>
					<Grid item xs={12}>
						<TextField fullWidth variant="outlined" label="City" value={city} onChange={(e) => setCity(e.target.value)} />
					</Grid>
					<Grid item xs={6}>
						<TextField fullWidth variant="outlined" label="State" value={state} onChange={(e) => setState(e.target.value)} />
					</Grid>
					<Grid item xs={6}>
						<TextField fullWidth variant="outlined" label="Postal Code" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
					</Grid>
					<Grid item xs={12}>
						<TextField fullWidth variant="outlined" label="County" value={county} onChange={(e) => setCounty(e.target.value)} />
						{customer.address.latitude === 0 && customer.address.longitude === 0 && (
							<Typography variant="overline">
								Not Geocoded <ButtonLink onClick={() => setManualGeocode(true)}>(Change)</ButtonLink>
							</Typography>
						)}
						{customer.address.latitude !== 0 && customer.address.longitude !== 0 && (
							<Typography variant="overline">
								{customer.address.latitude} {customer.address.longitude} <ButtonLink onClick={() => setManualGeocode(true)}>(Change)</ButtonLink>
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item>
								<Button color="primary" variant="contained" disabled={disabled} type="submit">
									Update
								</Button>
							</Grid>
							<Grid item>
								<Button color="secondary" variant="outlined" disabled={disabled} onClick={cancelUpdate}>
									Cancel
								</Button>
							</Grid>
							<GridGrow />
							<Grid item>
								<Button color="primary" variant="outlined" disabled={disabled} onClick={() => setMode("change")}>
									Change
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		);
	}

	return (
		<Grid container wrap="nowrap" alignItems="center" spacing={1}>
			<Grid item>
				<IconButton onClick={() => setMode("edit")} size="small" color="primary">
					<LocationIcon />
				</IconButton>
			</Grid>
			<Grid item>
				<Typography>{customer.address.street}</Typography>
				<Typography>
					{customer.address.city}, {customer.address.state} {customer.address.postalCode}
				</Typography>
				<Typography>{customer.address.county}</Typography>
				{customer.address.latitude === 0 && customer.address.longitude === 0 && (
					<Typography variant="overline" color="textSecondary">
						Address not geocoded
					</Typography>
				)}
			</Grid>
		</Grid>
	);
}

function CustomerTags() {
	const context = useCustomerDetailPage();
	const { customer } = context;
	const alert = useAlert();

	const [showSelectTag, setShowSelectTag] = useState(false);
	const [disable, setDisabled] = useState(false);

	const onAddTag = async (tag: Tag) => {
		setShowSelectTag(false);
		setDisabled(true);
		const result = await TagService.addToCustomer({ customerId: customer.id, tagId: tag.id });
		setDisabled(false);
		if (result.success) {
			context.updateCustomer(result.data);
			alert.success("Tag added");
		} else if (result.error) {
			alert.serverError(result);
		} else {
			alert.validation(result);
		}
	};

	const onRemoveTag = async (tag: Tag) => {
		setDisabled(true);
		const result = await TagService.removeFromCustomer({ customerId: customer.id, tagId: tag.id });
		setDisabled(false);
		if (result.success) {
			context.updateCustomer(result.data);
			alert.success("Tag removed");
		} else if (result.error) {
			alert.serverError(result);
		} else {
			alert.validation(result);
		}
	};

	return (
		<>
			<SelectTagDialog open={showSelectTag} type="Customer" onClose={() => setShowSelectTag(false)} onTagSelected={onAddTag} disableTags={customer.tags} />
			<Grid container alignItems="center" spacing={1}>
				{customer.tags.length > 0 && (
					<Grid item>
						<Typography>Customer Tags:</Typography>
					</Grid>
				)}

				{customer.tags.map((t) => (
					<Grid key={t.id} item>
						<TagBubble disabled={disable} tag={t} onDelete={onRemoveTag} />
					</Grid>
				))}
				<Grid item xs={12}>
					<Chip disabled={disable} variant="outlined" label="Add Tag" icon={<AddIcon />} onClick={() => setShowSelectTag(true)} />
				</Grid>
			</Grid>
		</>
	);
}

function CustomerDeliveryInstructions() {
	const context = useCustomerDetailPage();
	const { customer } = context;

	const [editDeliveryNotes, setDeliveryInstructions] = useState(false);

	if (editDeliveryNotes) {
		return (
			<Grid container spacing={1}>
				<Grid item>
					<SubmitField<Customer>
						type="text"
						autoFocus={false}
						selectOnFocus
						helperText="Delivery Instructions for this location"
						value={customer.deliveryInstructions}
						size="small"
						update={{
							request: (deliveryInstructions) => CustomerService.updateDeliveryInstructions({ customerId: customer.id, deliveryInstructions }),
							onSuccess: (c) => {
								context.updateCustomer(c);
								setDeliveryInstructions(false);
							},
							successMessage: "Customer Delivery Instructions Updated",
							required: false,
						}}
						onCancel={() => setDeliveryInstructions(false)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button color="secondary" variant="outlined" onClick={() => setDeliveryInstructions(false)}>
						Cancel
					</Button>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container wrap="nowrap" alignItems="center" spacing={1}>
			<Grid item>
				<IconButton onClick={() => setDeliveryInstructions(true)} size="small" color="primary">
					<LocalShippingIcon />
				</IconButton>
			</Grid>
			<Grid item>
				<Typography color={customer.deliveryInstructions ? "textPrimary" : "textSecondary"}>{customer.deliveryInstructions || "No Delivery Instructions"} </Typography>
			</Grid>
		</Grid>
	);
}
